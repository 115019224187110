import * as React from "react";
import levelImg from "../images/level-specs.jpg";
import levelVideo from "../images/Level_Video.mp4"
import { pageStyles } from "../styles/helpers";
import "../styles/global-styles.css";

// styles
const imageStyles = {
  marginTop: "-10px",
  marginBottom: 0,
  width: "100%",
};
const videoStyles = {
  marginTop: "-5px",
  marginBottom: 0,
  width: "100%",
};

// markup
const Level = () => {
  return (
    <main style={pageStyles}>
      <title>Level Specs</title>
      <img style={imageStyles} src={levelImg} />
      <video style={videoStyles} crossOrigin="anonymous" controls={false} autoPlay loop muted>
        <source src={levelVideo} type="video/mp4" />
      </video>
    </main>
  );
};

export default Level;
